
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.4);
}

/* Handle on click and hold */
::-webkit-scrollbar-thumb:active{
  background: rgba(0,0,0,.7);
}

._cUP1np9gMvFQrcPftuf.xahN8AEzyAvQtVj17TPv {
  margin-top: 0px !important;
}

.Collapsible__trigger {
  display: block;
  position: relative;
}

.Collapsible__trigger::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  position: absolute;
  top: 25px;
  right: 36px;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out;
}

.Collapsible__trigger.is-open::after {
  transform: rotate(135deg);
}

.link {
  color: blue;
  text-decoration: underline;
}